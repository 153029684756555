<template>
    <div class="Explore">
        <div class="newIn-main-list">
            <div class="main-list-item" v-loading="loadRight">
                <div class="list">
                    <div class="item" v-for="(item, index) in sortProductList" :key="index">
                        <div class="item-img">
                            <div class="img" @click="bagShopClick(item.sku_id)" @mouseover="item.imgHover = true" @mouseleave="item.imgHover = false">
                                <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer; width: 100%; height: 100%"></el-image>
                            </div>
                            <button @click.stop="bagShopClick(item.sku_id)">ADD TO BAG</button>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="40" layout="prev, pager, next" :total="total"></el-pagination>
        </div>

        <el-dialog :visible.sync="bagShop" @closed="handleClose" top="10vh" width="55vw">
            <BagShop :ids="ids" :num="1" @close="handleClose"></BagShop>
        </el-dialog>
    </div>
</template>

<style>
.Explore .el-collapse{
    border: none;
}
.Explore .el-collapse-item__wrap{
    border: none;
}
.Explore .el-collapse-item__header{
    border: none;
}
.Explore .el-slider__stop{
    background-color: #ECEEEE;
}
.Explore .el-drawer.ltr, .Explore .el-drawer.rtl, .Explore .el-drawer__container{
    overflow-y: auto;
}
.Explore .el-collapse-item__header {
    font-size: 18px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #330000;
}
.Explore .el-drawer__close-btn {
    outline: none;
}
.Explore .el-drawer__open .el-drawer.rtl {
    outline: none;
}
.Explore .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #330000;
    color: #fff;
}
.Explore .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background: #330000;
    color: #fff;
}
.Explore .el-pagination.is-background .btn-prev,
.Explore .el-pagination.is-background .btn-next,
.Explore .el-pagination.is-background .el-pager li {
    background: transparent;
    color: #330000;
}
.Explore .el-loading-mask {
    background-color: rgba(247, 249, 250, 0.7);
}
</style>
<style lang="scss" scoped>
@import "./Explore/Explore.scss";
</style>

<script>
import Explore from "./Explore/Explore.js"
import BagShop from "@/views/shop/components/bagShop.vue"

export default {
    name: "Explore",
    components: {
        BagShop
    },
    mixins: [Explore]
}
</script>