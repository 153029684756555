import { goodsSkuPage } from "@/api/goods/goods";
import { getToken } from "@/utils/auth"

export default {
    name: "Explore",
    components: {},
    data: () => {
        return {
            sortProductList: [],
            currentPage: 1,
            pageSize: 40,
            categoryId: sessionStorage.getItem("treeOneIdSoyego") || 4,
            is_overlay: false,
            total: 0,
            loadRight: true,
            ids: -1,
            bagShop: false,
        }
    },
    watch: {
        $route: "fetchData" 
    },
    created() {
        
    },
    mounted() {
        this.tokenIf();
        this.getGoodsList();
    },
    computed: { },
    methods: {
        handleCurrentChange(val) {
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0

            this.currentPage = val
            this.loadRight = true
            this.getGoodsList()      
        },
        handleClose() {
            this.bagShop = false;
        },
        bagShopClick(stuId) {
			this.ids = stuId;
			this.bagShop = true;
		},
        tokenIf() {
            let token = getToken();
            if(token == undefined){
                this.is_overlay = false;
            }else{
                this.is_overlay = true;
            }
        },
        //获取商品列表
        getGoodsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                is_overlay: this.is_overlay,
                category_id: this.categoryId,
            };
            goodsSkuPage(params || {}).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    // this.goodsTitle = data.list[0].category_name;
                    data.list.forEach(item => {
                        item.category_id = item.category_id.split(',');
                        item.goods_image = item.goods_image.split(',');                        
                        item.goods_spec_format = JSON.parse(item.goods_spec_format);

                        this.$set(item, 'imgHover', false);
                    });
                    this.sortProductList = data.list;
                    this.total = res.data.count;
                    this.loadRight = false;
                }
            }).catch(err => {
                this.loadRight = false;
                this.$message.error(err.message);
            });
        },
    }
}
